// window.$ = window.jQuery = require('jquery')
// require('./js/jquery')
// require('./js/main')
// require('./js/select')
// require('./js/lightbox-plus-jquery.min')

$(document).ready(function() {
    $('#send_contact').on('click', function() {
        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/send_message'

        if ($('#phone').val() === '' || $('#name').val() === '' || $('#email').val() === '' || $('#text').val() === '') {
            Swal.fire($('#send_error_text').val())
            return
        }

        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
            data: {
                'name': $('#name').val(),
                'email': $('#email').val(),
                'phone': $('#phone').val(),
                'text': $('#text').val()
            },
            success: function(response) {
                $('.cpopup').addClass('cpopup--active')

                // Swal.fire(response.message)
            }
        })
    })

    // Global Search
    $('#search-terms').on('keyup', function() {

        const keyword = $(this).val()

        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/global_search'

        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
            data: {
                keyword: keyword
            },
            success: function(response) {
                $('#search_result').html(response)
            }
        })
    })

    // filter request
    $('.filterbt').on('click', function() {
        const id = $(this).data("id")
        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/filter'

        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
            data:{
                sub_cat_id:id
            },
            success: function(response) {
                $('#filter'+id).html(response)
            }
        })
    })


    // filter result request
    $(document).on('click', '.filter_yaer_btn', function(){

        const date = $(this).data("date")
        const id = $(this).data("id")
        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/filter_result'
        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
            data:{
                date:date
            },
            success: function(response) {
                $('#filter_result_body'+id).html(response)
            }
        })
    })

    // Gallery photo Request
    $('#image_request').on('click', function() {
        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/gallery_photo'

        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },

            success: function(response) {
                $('#image_gallery_result').html(response.data)
            }
        })
    })

    // Gallery video Request
    $('#video_request').on('click', function() {
        const url = document.querySelector('meta[name="home"]').getAttribute('content') + '/gallery_video'

        $.ajax({
            type: 'POST',
            url: url,
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },

            success: function(response) {
                $('#video_gallery_result').html(response.data)
                console.log(response)
            }
        })
    })

})

